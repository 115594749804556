import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";
import HeroOther from "../components/hero-other";
import { checkPropertiesForNull } from "../utils/object";
import uuid4 from "uuid4";
import { SafeHtmlParser } from "../components/safe-html-parser";

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "contact-us" }) {
        seoFieldGroups {
          localBusinessSchema
          metaDescription
          metaTitle
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        contactUsPageFieldGroups {
          contactBannerSection {
            description
            heading
            subHeading
            cta1 {
              target
              title
              url
            }
            cta2 {
              target
              title
              url
            }
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          contactGetInTouch {
            formBtnLabel
            heading
            subHeading
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          enquiriesSection {
            heading
            subHeading
            googleMap
            address {
              address
              heading
              link
            }
            email {
              heading
              link {
                target
                title
                url
              }
            }
            officeHours {
              description
              heading
              socialIcon {
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                        width: 56
                      )
                    }
                  }
                }
                url
              }
            }
            phone {
              heading
              link {
                target
                title
                url
              }
            }
            thePantry {
              description
              heading
              socialIcon {
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                        width: 56
                      )
                    }
                  }
                }
                url
              }
            }
          }
        }
      }

      # allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
      #   nodes {
      #     localFile {
      #       childImageSharp {
      #         gatsbyImageData(
      #           formats: [AUTO, WEBP]
      #           quality: 100
      #           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
      #           layout: CONSTRAINED
      #           placeholder: BLURRED
      #         )
      #       }
      #     }
      #     caption

      #     permalink
      #     timestamp
      #   }
      # }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, contactUsPageFieldGroups },
    site,
  } = data;

  const { contactBannerSection, contactGetInTouch, enquiriesSection } =
    contactUsPageFieldGroups;

  const siteUrl = site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Contact Us",
        item: {
          url: `${siteUrl}/contact-us`,
          id: `${siteUrl}/contact-us`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/contact-us`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {contactBannerSection &&
            !checkPropertiesForNull(contactBannerSection, ["heading"]) && (
              <HeroOther
                smallHeading={contactBannerSection?.subHeading}
                title={contactBannerSection?.heading}
                externalLink={contactBannerSection?.cta1.target === "_blank"}
                button1Text={contactBannerSection?.cta1?.title}
                button1Link={contactBannerSection?.cta1?.url}
                button2Text={contactBannerSection?.cta2?.title}
                button2Link={contactBannerSection?.cta2?.url}
                description={
                  <>
                    <SafeHtmlParser
                      htmlContent={contactBannerSection?.description}
                    />
                  </>
                }
                backgroundImage={
                  contactBannerSection.backgroundImage.localFile.childImageSharp
                    .gatsbyImageData
                }
              />
            )}
          {enquiriesSection &&
            !checkPropertiesForNull(enquiriesSection, ["heading"]) && (
              <section className="pt-5 pt-lg-7">
                <Container>
                  <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                    <Col lg={8}>
                      {enquiriesSection?.subHeading && (
                        <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                          {enquiriesSection?.subHeading}
                        </p>
                      )}

                      <h2 className="line-heading-dark text-center  text-dark-green mb-5 fs-1">
                        {enquiriesSection?.heading}
                      </h2>
                    </Col>
                  </Row>

                  <Row className="g-5 gx-lg-7 align-items-center">
                    <Col lg={6}>
                      <iframe
                        className="position-relative google-map"
                        src={
                          enquiriesSection?.googleMap ??
                          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2473.0311425588225!2d0.1911667763615362!3d51.695875196917385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d897e47c2e754f%3A0xb7f81d8f596b4b47!2sToot%20Hill%20Golf%20Club!5e0!3m2!1sen!2suk!4v1695664623164!5m2!1sen!2suk"
                        }
                        width="100%"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Col>
                    <Col lg={6}>
                      {" "}
                      {enquiriesSection.address && (
                        <>
                          <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                            {enquiriesSection?.address.heading}
                          </p>
                          <a
                            className="black-link fs-6 "
                            target="_blank"
                            rel="noreferrer"
                            href={enquiriesSection?.address.link}
                          >
                            {enquiriesSection?.address.address && (
                              <SafeHtmlParser
                                htmlContent={enquiriesSection?.address?.address}
                              />
                            )}
                          </a>
                        </>
                      )}
                      {enquiriesSection.phone && (
                        <>
                          <p className="text-uppercase pt-4 text-secondary pb-2 mb-0 ssp-semibold">
                            {enquiriesSection.phone?.heading}
                          </p>
                          <a
                            className="black-link fs-6 "
                            href={enquiriesSection.phone?.link.url}
                            taget={
                              enquiriesSection.phone?.link?.target ?? "_blank"
                            }
                          >
                            {enquiriesSection.phone?.link.title}
                          </a>
                        </>
                      )}
                      {enquiriesSection.email && (
                        <>
                          <p className="text-uppercase  pt-4 text-secondary pb-2 mb-0 ssp-semibold">
                            {enquiriesSection.email?.heading}
                          </p>
                          <a
                            className="black-link fs-6 "
                            href={enquiriesSection.email?.link.url}
                            taget={
                              enquiriesSection.email?.link?.target ?? "_blank"
                            }
                          >
                            {enquiriesSection.email?.link.title}
                          </a>
                        </>
                      )}
                      <p className="text-uppercase  text-secondary pt-4 pb-2 mb-0 ssp-semibold">
                        {enquiriesSection?.officeHours?.heading ??
                          "Office Hours"}
                      </p>
                      {enquiriesSection?.officeHours?.description && (
                        <div className="pb-0 mb-0 fs-6 ssp-semibold content-bold">
                          <SafeHtmlParser
                            htmlContent={
                              enquiriesSection?.officeHours?.description
                            }
                          />
                        </div>
                      )}
                      {enquiriesSection?.officeHours?.socialIcon &&
                        enquiriesSection?.officeHours?.socialIcon.length >
                          0 && (
                          <div className=" mt-3 d-flex  align-items-center">
                            {enquiriesSection?.officeHours?.socialIcon.map(
                              (item, i) => (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={item.url}
                                  key={uuid4()}
                                >
                                  <GatsbyImage
                                    className={`me-4 d-inline-block`}
                                    image={
                                      item.image.localFile.childImageSharp
                                        .gatsbyImageData
                                    }
                                    style={{
                                      width:
                                        i + 1 ===
                                        enquiriesSection?.officeHours
                                          ?.socialIcon.length
                                          ? "46px"
                                          : "56px",
                                    }}
                                  />
                                </a>
                              )
                            )}
                          </div>
                        )}
                      <p className="text-uppercase  text-secondary pt-4 pb-2 mb-0 ssp-semibold">
                        {enquiriesSection?.thePantry?.heading ?? "The Pantry"}
                      </p>
                      {enquiriesSection?.thePantry?.description && (
                        <div className="pb-0 mb-0 fs-6 ssp-semibold content-bold">
                          <SafeHtmlParser
                            htmlContent={
                              enquiriesSection?.thePantry?.description
                            }
                          />
                        </div>
                      )}
                      {enquiriesSection?.thePantry?.socialIcon &&
                        enquiriesSection?.thePantry?.socialIcon.length > 0 && (
                          <div className=" mt-3 d-flex  align-items-center">
                            {enquiriesSection?.thePantry?.socialIcon.map(
                              (item) => (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={item.url}
                                  key={uuid4()}
                                >
                                  <GatsbyImage
                                    className=" me-4  d-inline-block "
                                    image={
                                      item.image.localFile.childImageSharp
                                        .gatsbyImageData
                                    }
                                  />
                                </a>
                              )
                            )}
                          </div>
                        )}
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          {contactGetInTouch &&
            !checkPropertiesForNull(contactGetInTouch, ["heading"]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                    <Col lg={8}>
                      {contactGetInTouch.subHeading && (
                        <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                          {contactGetInTouch.subHeading}
                        </p>
                      )}

                      <h2 className="line-heading-dark text-center  text-dark-green mb-5 fs-1">
                        {contactGetInTouch.heading}
                      </h2>
                    </Col>
                  </Row>

                  <Row className="g-5 justify-content-between align-items-center h-100 gx-lg-7  gx-xl-0">
                    <Col lg={6} xl={5}>
                      <ContactForm buttonText={contactGetInTouch?.formBtnLabel}/>
                    </Col>
                    {contactGetInTouch?.image && (
                      <Col styl={{ minHeight: "100%" }} lg={6} xl={5}>
                        <div
                          className="h-100"
                          style={{ borderRadius: "8px", overflow: "hidden" }}
                        >
                          <GatsbyImage
                            style={{ borderRadius: "8px" }}
                            className="w-100 h-100"
                            image={
                              contactGetInTouch?.image.localFile.childImageSharp
                                .gatsbyImageData
                            }
                            alt={contactGetInTouch?.image.altText}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );
};

export default ContactUsPage;
